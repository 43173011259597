import { send } from 'emailjs-com'
import { Formik } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import { Button } from '../../components'
import {
	CheckmarkIcon,
	CorrectMessage,
	CrossIcon,
	ErrorMessage,
	FileIcon,
	FileLabel,
	FilterCheckbox,
	FormHeader,
} from './styled'

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Введите имя')
		.matches(/^[а-яА-ЯёЁ\s]+$/, 'Имя должно содержать только русские буквы'),

	phone: Yup.string()
		.required('Введите телефон')
		.matches(/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/, 'Неверный формат телефона'),

	email: Yup.string()
		.required('Введите адрес электронной почты')
		.email('Неверный адрес почты'),

	consent: Yup.boolean().oneOf(
		[true],
		'Необходимо согласие на обработку персональных данных'
	),
	file: Yup.mixed(),
})

const InputWrapper = ({
	name,
	label,
	placeholder,
	errors = {},
	touched = {},
	values = {},
	handleChange,
	handleBlur,
	mask = null, // опциональная маска для телефона
}) => {
	return (
		<div className='flex flex-col gap-2 w-full' style={{ maxWidth: '300px' }}>
			{/* Вывод сообщений об ошибках или корректности */}
			{errors[name] && touched[name] ? (
				<ErrorMessage>
					<CrossIcon>&#10006;</CrossIcon>
					{errors[name]}
				</ErrorMessage>
			) : values[name] && touched[name] ? (
				<CorrectMessage>
					<CheckmarkIcon>&#10003;</CheckmarkIcon>
					{label} введено верно
				</CorrectMessage>
			) : null}

			{/* Поле ввода */}
			{mask ? (
				<InputMask
					mask={mask}
					className='valueForm p-4'
					name={name}
					placeholder={placeholder}
					onChange={handleChange}
					onBlur={handleBlur}
					value={values[name]}
					style={{
						borderColor: errors[name]
							? 'red'
							: values[name] && touched[name]
							? 'rgb(245, 101, 12)'
							: 'rgb(45, 45, 45)',
						width: '100%',
					}}
				/>
			) : (
				<input
					className='valueForm p-4'
					type={name === 'email' ? 'email' : 'text'}
					name={name}
					onChange={handleChange}
					onBlur={handleBlur}
					value={values[name]}
					style={{
						borderColor: errors[name]
							? 'red'
							: values[name] && touched[name]
							? 'rgb(245, 101, 12)'
							: 'rgb(45, 45, 45)',
						width: '100%',
					}}
					placeholder={placeholder}
				/>
			)}
		</div>
	)
}

const Form = () => {
	const [fileLoaded, setFileLoaded] = useState(false)

	const onSubmit = (values, { setSubmitting }) => {
		const toSend = {
			name: values.name,
			phone: values.phone,
			email: values.email,
			message: values.message,
			file: values.file ? values.file.name : undefined, // передаем имя файла, если он загружен
		}

		send('service_o32fxro', 'template_c0lxiuj', toSend, 'uRUT4bm_siS8iuQbU')
			.then(response => {
				toast.success('Заявка принята')
			})
			.catch(err => {
				toast.error('Произошла ошибка отправки:' + err)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}
	// onSubmit когда будет сервер

	// const onSubmit = (values, { setSubmitting }) => {
	// 	const formData = new FormData();

	// 	formData.append('name', values.name);
	// 	formData.append('phone', values.phone);
	// 	formData.append('email', values.email);
	// 	formData.append('message', values.message);

	// 	if (values.file) {
	// 		formData.append('file', values.file);
	// 	}

	// 	fetch('https://serverURL/api/submit', {
	// 		method: 'POST',
	// 		body: formData,
	// 	})
	// 		.then(response => {
	// 			if (response.ok) {
	// 				return response.json();
	// 			}
	// 			throw new Error('Ошибка при отправке формы');
	// 		})
	// 		.then(data => {
	// 			console.log('SUCCESS!', data);
	// 		})
	// 		.catch(err => {
	// 			console.log('FAILED...', err);
	// 		})
	// 		.finally(() => {
	// 			setSubmitting(false);
	// 		});
	// };

	return (
		<div id='formBlock' className='form'>
			<FormHeader>
				<p>
					ЕСЛИ ВАС ИНТЕРЕСУЕТ 3D ВИЗУАЛИЗАЦИЯ, ПРОСТО ПРЕДОСТАВЬТЕ НАМ ПЛАН
					ПОМЕЩЕНИЯ И ПРИМЕРЫ ОЖИДАЕМОГО РЕЗУЛЬТАТА.
				</p>
				<p className='bold'>
					В ДАЛЬНЕЙШЕМ МЫ ОБСУДИМ ВСЕ ДЕТАЛИ ПРОЕКТА, ЧТОБЫ ТОЧНО ПОНЯТЬ ВАШИ
					ПОТРЕБНОСТИ И ЖЕЛАНИЯ.
				</p>
			</FormHeader>

			<Formik
				initialValues={{
					name: '',
					phone: '',
					email: '',
					message: '',
					file: undefined,
					consent: false,
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form className='formBlock' onSubmit={handleSubmit}>
						<FormHeader>
							<h3>Свяжитесь с нами</h3>
							<p>
								Для того чтобы заказать смету, оформите заявку обратной связи.
								Если у Вас остались вопросы по стоимости и срокам, напишите на
								почту: info@artell.studio
							</p>
						</FormHeader>

						<div
							className='flex flex-col justify-between items-center gap-4 md:flex-row md:gap-8 lg:gap-12'
							style={{
								width: '100%',
								maxWidth: '1200px', // ограничим максимальную ширину для больших экранов
								margin: '0 auto', // центрирование на больших экранах
								padding: '0 16px', // добавим внутренний отступ для маленьких экранов
							}}
						>
							{/* Блок имени */}
							<InputWrapper
								name='name'
								label='Имя'
								placeholder='Введите имя'
								errors={errors}
								touched={touched}
								values={values}
								handleChange={handleChange}
								handleBlur={handleBlur}
							/>

							{/* Блок телефона */}
							<InputWrapper
								name='phone'
								label='Телефон'
								placeholder='Телефон'
								errors={errors}
								touched={touched}
								values={values}
								handleChange={handleChange}
								handleBlur={handleBlur}
								mask='+7 (999) 999-99-99' // Маска для телефона
							/>

							{/* Блок email */}
							<InputWrapper
								name='email'
								label='Email'
								placeholder='Введите email'
								errors={errors}
								touched={touched}
								values={values}
								handleChange={handleChange}
								handleBlur={handleBlur}
							/>
						</div>
						<textarea
							className='textareaValue p-4'
							name='message'
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.message}
							placeholder='Сообщение'
						/>
						<div className='flex items-center justify-center'>
							<input
								type='file'
								name='file'
								onChange={e => {
									const file = e.target.files[0]
									setFileLoaded(!!file)
									setFieldValue('file', file)
								}}
								onBlur={handleBlur}
								id='file-input'
								style={{ display: 'none' }}
							/>
							<FileLabel htmlFor='file-input' className='file-label'>
								{fileLoaded ? (
									<>
										<CheckmarkIcon>&#10003;</CheckmarkIcon>
										Файл загружен
									</>
								) : (
									<>
										<FileIcon>&#128206;</FileIcon>
										{values.file ? values.file.name : 'Загрузить файл'}
									</>
								)}
							</FileLabel>
						</div>
						<FilterCheckbox>
							<input
								type='checkbox'
								name='consent'
								checked={values.consent}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							Согласие на обработку персональных данных
						</FilterCheckbox>
						<Button
							type='submit'
							disabled={
								isSubmitting ||
								!values.consent ||
								!values.name ||
								!values.phone ||
								!values.email
							}
						>
							Отправить заявку
						</Button>
					</form>
				)}
			</Formik>
		</div>
	)
}

export default Form
