import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { NavMenu } from '..'
import styles from './Header.module.scss'
import Logo from './logo'

const Header = ({ targetBlockRef, openModal }) => {
	const [showHeader, setShowHeader] = useState(true)
	const [lastScrollY, setLastScrollY] = useState(0)

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY

			if (currentScrollY > lastScrollY) {
				setShowHeader(false)
			} else {
				setShowHeader(true)
			}

			setLastScrollY(currentScrollY)

			if (targetBlockRef.current) {
				const blockRect = targetBlockRef.current.getBoundingClientRect()
				if (blockRect.top <= 110 && blockRect.bottom >= 0) {
					setShowHeader(false) // Скрываем хедер над блоком Demo
				} else {
					setShowHeader(true) // Показываем хедер, если блок Demo не в зоне видимости
				}
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [lastScrollY, targetBlockRef])

	return (
		<div
			className={classNames(
				styles.header,
				showHeader ? styles['header--visible'] : styles['header--hidden']
			)}
		>
			<Logo />
			<NavMenu openModalWithData={openModal} />
		</div>
	)
}

export default Header
