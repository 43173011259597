import classNames from 'classnames'
import React from 'react'
import styles from './About.module.scss'

const About = () => {
	return (
		<div className={classNames(styles.about)}>
			<div className={styles.wrapTextContent}>
				<h1 className='aboutUseTitle'>
					ИНТЕРАКТИВНАЯ ВИЗУАЛИЗАЦИЯ АРХИТЕКТУРЫ
				</h1>

				<div className={styles.wrapP}>
					<p className={classNames('text', styles.text)}>
						Одним из ключевых направлений студии ArtTell является разработка для
						девелоперов и застройщиков специализированных веб-сервисов с
						интерактивной демонстрацией фасадов, интерьеров и прилегающих
						территорий.
					</p>
					<p className={classNames('text', styles.text)}>
						Наши решения позволяют полностью избавиться от территориальных
						ограничений и максимально эффективно презентовать объекты
						недвижимости покупателям со всего мира прямо на Вашем сайте.
					</p>
					<p className={classNames('text', styles.text)}>
						Мы выведем ваши продажи на новый, ЦИФРОВОЙ УРОВЕНЬ!
					</p>
				</div>
			</div>

			<div className={styles.wrapImg}>
				<video
					className={styles.imgGif}
					autoPlay
					muted
					loop
					playsInline
					preload='auto'
					src='images/Build_gif.webm'
				/>
			</div>
		</div>
	)
}

export default About
