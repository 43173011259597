import styled from 'styled-components'
import { Tags } from '../../dummy'

const Toggle = styled.div`
	height: 100%;
	width: 30px;
	background: rgb(245, 101, 12);
	right: 0;
	border-radius: 8px 0 0 8px;
	position: absolute;
	color: #1a1717;
	text-align: center;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	writing-mode: tb;
	transform: rotate(180deg);
	cursor: pointer;
	justify-content: center;

	&::before {
		content: url(${props =>
			props.isOpen
				? 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tcmlnaHQiPjxwYXRoIGQ9Im05IDE4IDYtNi02LTYiLz48L3N2Zz4='
				: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWNoZXZyb24tbGVmdCI+PHBhdGggZD0ibTE1IDE4LTYtNiA2LTYiLz48L3N2Zz4='});
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 29px;
		width: 30px;
		height: 100px;
		background-color: rgb(245, 101, 12);
		border-radius: 8px 0px 0px 8px;

		/* Анимация мерцания или пробегания света */
		animation: shine 2s infinite;

		/* Определение анимации */
		@keyframes shine {
			0% {
				box-shadow: inset 0 0 10px rgba(255, 255, 255, 0); /* Начало без свечения */
			}
			50% {
				box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.8); /* Максимальное свечение */
			}
			100% {
				box-shadow: inset 0 0 10px rgba(255, 255, 255, 0); /* Возвращение к исходному состоянию */
			}
		}
	}
`

const PortfolioPanel = styled.div`
	position: absolute;
	top: 0;
	left: 30px;
	transform: ${props =>
		props.isOpen ? 'translateX(calc(0% - 30px))' : 'translateX(-100%)'};
	width: 40%;
	height: 100%;
	background: rgb(38, 38, 38);
	color: #ffffff;
	border-radius: 0 8px 8px 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	transition: transform 0.5s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	z-index: 10;

	@media (max-width: 768px) {
		width: 90%;
	}
`

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	width: 100%;
	flex-direction: column;
	height: 100%;
	padding: 20px;
	gap: 20px;
	align-items: flex-start;
`

const FilterCheckbox = styled.div`
	display: flex;
	align-items: center;
	margin-right: 20px;

	input[type='checkbox'] {
		appearance: none;
		min-width: 20px;
		height: auto;
		aspect-ratio: 1 / 1;
		border: 2px solid;
		border-radius: 4px;
		margin-right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: border-color 0.3s ease;

		&:hover {
			border-color: #f56512;
		}
	}
`

const PortfolioPanelContent = ({
	children,
	disabled = false,
	onClick,
	isOpen,
	selectedFilter,
	onFilterChange,
}) => {
	return (
		<PortfolioPanel isOpen={isOpen} disabled={disabled}>
			<Toggle isOpen={isOpen} onClick={!disabled ? onClick : undefined}>
				{children}
			</Toggle>
			<FilterContainer>
				{Tags.map((tag, index) => (
					<RenderTag
						key={tag.name}
						tag={tag}
						index={index}
						selectedFilter={selectedFilter}
						onFilterChange={onFilterChange}
					/>
				))}
			</FilterContainer>
		</PortfolioPanel>
	)
}

const RenderTag = ({ tag, selectedFilter, onFilterChange, index }) => {
	const isSelected =
		selectedFilter === tag.name || (index === 0 && selectedFilter === null)

	return (
		<FilterCheckbox>
			{/* <input
				type='checkbox'
				checked={isSelected}
				onChange={() => onFilterChange(isSelected ? null : tag.name)}
			/>
			<p>{tag.name}</p> */}
			<div className='form-control'>
				<label className='cursor-pointer label'>
					<input
						type='checkbox'
						checked={isSelected}
						onChange={() => onFilterChange(tag.name)}
						className='checkbox checkbox-warning'
					/>
					<span className='label-text text-white'>{tag.name}</span>
				</label>
			</div>
		</FilterCheckbox>
	)
}

export default PortfolioPanelContent
